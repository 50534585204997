<div class="layout-content">
    <div id="overlay" [ngStyle]="{ display : loader }">
        <div class="spinner"></div>
        <br />
        Loading...
    </div>
    <!-- Content -->
    <div class="container flex-grow-1 container-p-y">

        <!-- Header -->
        <div class="theme-bg-white mb-4 marginTop">
            <div class="row pb-5 pt-5 mx-auto flex-container" style="padding-right: 1rem;padding-left: 1rem;">
                <h3>Office de Promotion et de Gestion Immobilière de Constantine</h3>
                <h3>ديوان الترقية و التسيير العقاري لقسنطينة</h3>
            </div>
            <div class="pb-5 pt-5 mx-auto container-images" style="padding-right: 1rem;padding-left: 1rem;">
                <div>
                    <img src="../../assets/images/logo.jpg" alt="" class="d-block ui-w-100">
                </div>
                <div>
                    <img src="../../assets/images/algPoste.png" alt="" class="d-block ui-w-100 pull-right">
                </div>
                <div>
                    <img src="../../assets/images/logo.svg" alt="" class="d-block ui-w-100 pull-right">
                </div>
            </div>
            <div class="container-client-info py-5 mx-auto" style="padding-right: 1rem; padding-left: 1rem;">
                <div *ngIf="Obj">
                    <div class="container-name">
                        <h4 class="font-weight-bold mb-4">
                            {{Obj.benificaire.nom}} {{Obj.benificaire.prenom}}
                        </h4>
                        <button class="btn btn-primary ml-5 pull-right" (click)="onLogout()"><span
                                class="fa fa-sign-out-alt fa-lg"></span>
                            Déconnecter
                        </button>
                    </div>
                    <div class="text-muted mb-4">
                        Cité {{Obj.patrimoine.cite.libelle}}
                        BT {{Obj.patrimoine.adresse.codeBatiment}}
                        Bloc {{Obj.patrimoine.adresse.codeBloc}}
                        Etage {{Obj.patrimoine.adresse.numeroEtage}}
                        N° {{Obj.patrimoine.numPorte}}
                    </div>
                    <label>Patrimoine N° :</label>
                    <input type="text" class="form-control form-control-success" [ngModel]="Obj.patrimoine.numero"
                        readonly="readonly" id="idnum">
                </div>
            </div>

            <hr class="m-0">
        </div>
        <!-- Header -->

        <div class="row">
            <div class="col">

                <!-- Info -->
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="row justify-content-md-center">
                            <!-- <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12"></div> -->
                            <div class="col-12 text-center">
                                <label class="checkbox-inline">
                                    <input type="checkbox" (change)="checkBoxvalue()" value=""><a class="hrefCondition"
                                        (click)="showConditions()"> J'accepte les conditions générales
                                        d'utilisations</a>
                                </label>
                            </div>
                        </div>
                        <div class="cc-selector-2 container-payment-card">
                            <div>
                                <input id="visa2" type="radio" [checked]="!isDahabiyaCheked" (click)="onChangeCard()"
                                    name="creditcard" value="cib" />
                                <label class="drinkcard-cc cib" for="visa2"></label>
                            </div>
                            <div>
                                <input [checked]="isDahabiyaCheked" (click)="onChangeCard()" id="mastercard2"
                                    type="radio" name="creditcard" value="dahabiya" />
                                <label class="drinkcard-cc dahabiya" for="mastercard2"></label>
                            </div>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <label>nombre de mois à réglé :</label>
                                    <input type="number" min="0" class="form-control form-control-success"
                                        (keyup)="moisReglerEntred()" name="moisRegler" [(ngModel)]="nbrMoisRegle"
                                        placeholder="">
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <label>Montant réglé :</label>
                                    <input type="text" class="form-control form-control-success" placeholder=""
                                        (change)="montantRegler()" [(ngModel)]="montantRegle"
                                        (focus)="focusMontantRegle()" name="montantRegle" (blur)="blurMontantRegle()"
                                        style="background-color: #FFFFFF;font-weight: bold; text-align: right; color: #000000;">
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <label>Frais mise en demeure :</label>
                                    <input type="text" min="0" class="form-control form-control-success" name="frais"
                                        readonly [ngModel]="farisMiseDemeure | number:'1.2-2' "
                                        style="text-align: right;">
                                </div>
                            </div>
                        </form>
                        <div style="padding-bottom: 10px;padding-top: 10px;">
                            <div class="table-responsive"
                                style="height: 300px; border-style: solid; border-width: 1px; font-size: 15px; font-size: 13px;">
                                <table id="simpletable" class="table table-striped table-bordered nowrap">
                                    <thead class="fixedHeader">
                                        <tr>
                                            <th>N° </th>
                                            <th>Année</th>
                                            <th>Mois</th>
                                            <th>Loyer Principal</th>
                                            <th>Charge Locative</th>
                                            <th>pénalité de retard</th>
                                            <th>Tva</th>
                                            <!-- <th>Timbre</th> -->
                                            <th>TTC</th>
                                            <!--<th>Comul TTC</th>-->
                                        </tr>
                                    </thead>

                                    <tbody class="scrollContent">
                                        <tr *ngIf="listConstatation3.length == 0 ; else elseClause">
                                            <td colspan="10"
                                                style="text-align: center;  font-weight: bold; color: red;">
                                                aucun résultat </td>
                                        </tr>
                                        <ng-template #elseClause>
                                            <tr *ngFor="let constatation of listConstatation3, let index = index">
                                                <td [ngClass]="getColor(constatation.color)">{{index+1}}</td>

                                                <td [ngClass]="getColor(constatation.color)">{{constatation.annee}}</td>

                                                <td [ngClass]="getColor(constatation.color)">{{constatation.mois}}</td>

                                                <td [ngClass]="getColor(constatation.color)">
                                                    {{constatation.montant.loyer_principal |
                                                    number:'1.2-2'}}</td>

                                                <td [ngClass]="getColor(constatation.color)">
                                                    {{constatation.montant.charge_locative |
                                                    number:'1.2-2'}}</td>

                                                <td [ngClass]="getColor(constatation.color)">
                                                    {{constatation.montant.penalite_retard |
                                                    number:'1.2-2'}}</td>

                                                <td [ngClass]="getColor(constatation.color)">{{constatation.montant.tva
                                                    | number:'1.2-2'}}</td>

                                                <!-- <td [ngClass]="getColor(constatation.color)">
                                                    {{constatation.montant.timber | number:'1.2-2'}}
                                                </td> -->

                                                <td [ngClass]="getColor(constatation.color)">
                                                    {{constatation.montant.arriere_ttc
                                                    | number:'1.2-2'}}</td>

                                                <!--
                                <td *ngIf="constatation.color == '0' ; else one " class="bg-danger">{{number(constatation.montant.ttcComul)}}</td>
                                <td *ngIf="constatation.color == '1' ;else four " class="bg-warning">{{number(constatation.montant.ttcComul)}}</td>
                                <td *ngIf="constatation.color == '4', else default " class="bg-primary">{{number(constatation.montant.ttcComul)}}</td>
                                <td  class="bg-success">{{number(constatation.montant.ttcComul)}}</td>-->
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div *ngIf="bGris == 1" class=" col-sm-12  " style="text-align: right;">
                                        <button id="left" class="btn" (click)="paginationLeft()"><i
                                                class="icofont icofont-double-left"></i></button>
                                        <button id="right" class="btn" (click)="paginationRight()"><i
                                                class="icofont icofont-double-right"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-md-center">
                                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
                                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
                                    <button class="btn btn-primary btn-lg" (click)="sauvegarder()"><span
                                            style="font-family:monospace;font-size: 20px;">Payer :
                                            <b>{{montantRegle}}</b></span>
                                        <!-- <img src="../../assets/images/dahabiya.png" /> -->
                                    </button>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>

                        <!-- / Posts -->

                    </div>

                </div>

            </div>
            <!-- / Content -->
            <div class="modal fade" id="modalYT" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">


                    <div class="modal-content" style="width: 1000px; height: 629px ;">


                        <div class="modal-body mb-0 p-0">

                            <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                                <iframe #myiFrame class="embed-responsive-item" allowfullscreen></iframe>
                            </div>

                        </div>


                        <div class="modal-footer justify-content-center">
                            <span class="mr-4"> </span>
                            <button type="button" class="btn btn-outline-primary btn-danger"
                                data-dismiss="modal">Fermer</button>

                        </div>

                    </div>


                </div>
            </div>
        </div>
        <div class="mt-3" style="text-align: center;">
            <h5>Copyright &copy; {{year}} OPGI. Powered by <a href="https://www.idweb-dz.com/" target="_blank" style="
            font-size: 20px !important;">IDWEB</a>
            </h5>
        </div>
    </div>
</div>