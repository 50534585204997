import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { exhaustMap, take } from "rxjs/operators";
import { CommenService } from "../commen.service";

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
    constructor(private _commenService:CommenService){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this._commenService.loginSubject.pipe(
            take(1),
            exhaustMap(login =>{
                if(!login){
                    return next.handle(req);
                }
                console.log('url get benif out tracker : ',login)
                const newReq = req.clone({headers: new HttpHeaders().set('Authorization','Bearer '+login.token)})
                console.log('url get benif out new request : ',newReq)
                return next.handle(newReq);
            })
        );
        
    }

}