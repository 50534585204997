import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { CommenService } from '../commen.service';
import { AffectationObjet } from '../models/affectationObject.model';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Constatation } from '../models/constatation.model';
import { ObjPaiement } from '../models/objPaiement.model';
import { CryptoJsService } from '../crypto-service';
import { GetUrlEpaymentDTO } from '../models/getUrlDTO.model';

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.css']
})
export class PaiementComponent implements OnInit {
  @ViewChild('myiFrame', { static: true }) myiFrame: ElementRef;
  listConstatation = [];
  listConstatation2 = [];
  listConstatation3 = [];
  constatationAjouterApayer = [];
  listepaimentLoyerInserer = [];
  comulTTC = 0;
  de = 0;
  a = 0;
  de1 = 0;
  a1 = 0;
  annee = 0;
  bGris = 0;
  constatationInPaimentLoyer = 0;
  farisMiseDemeure = 0;
  sauvgardeInterdit = 0;
  nbrMoisRegle = 0;
  change = 0;
  idCaisse = 30;
  montantRegle = this.number(0);
  n = 0;
  m = 0;
  idConstatation = 0;
  numCheque = '0';
  espece = 'EPAIEMENT';
  Obj: AffectationObjet;
  getUrlObj = new GetUrlEpaymentDTO();
  loader: string = 'none';
  isConditionCheked = false;
  isDahabiyaCheked = true;
  timeOut: any;
  engagement_entete: {
    id: 0,
    num_engagement: 0,
    type_ci: '',
    id_affectation: 0,
    date_engagement: Date,
    num_ci: '',
    date_livrance: Date,
    lieu_delivrence: '',
    periode_impayer: 0,
    montant_impayer: 0,
    telephone: '',
    nombre_mois_a_payer: 0,
    nbrs_mois_initiale: 0,
    mois_depart: '',
    annee_depart: '',
    terminer: false,
    adresse: '',
    nomEtprenom: '',
    paiement: false,
  };
  year = new Date().getFullYear();

  constructor(private _commenService: CommenService, private _decimalPipe: DecimalPipe, private _route: Router, private _cryptoService: CryptoJsService) { }

  getBenif() {
    this.loader = 'inline-block'
    console.log('in get benig');
    this._commenService.getBenificiaire().subscribe((data: any) => {
      this.Obj = data;
      this.getEngagementEntete();
      this.getConstatation();
      this.ajouterConstatationApayer();
      this.calculeFrais();
    }, err => {
      this.loader = 'none';
      Swal.fire({
        title: 'Bénificiaire ' + err.response.statusText,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      })
    });
  }

  getConstatation() {
    this._commenService.getConstatation(this.Obj.id, this.Obj.patrimoine.id).subscribe((response: any) => {
      console.log('???????????',response);
      this.listConstatation = [];
      this.listConstatation2 = [];
      var j = 0
      if (response.length >= 1) {

        // if (response.length > '1') {
          for (var i = 0; i < response.length; i++) {
            this.listConstatation.push({
              id: response[i].id,
              annee: response[i].annee,
              mois: response[i].mois,
              montant: {
                abbatement: response[i].montant.abbatement,
                arriere_ttc: response[i].montant.arriere_ttc,
                charge_arrieres: response[i].montant.charge_arrieres,
                charge_locative: response[i].montant.charge_locative,
                isov: response[i].montant.isov,
                layer_net: response[i].montant.layer_net,
                loyer_arrieres: response[i].montant.loyer_arrieres,
                loyer_principal: response[i].montant.layer_net,
                penalite_retard: response[i].montant.penalite_retard,
                tax_arrieres: response[i].montant.tax_arrieres,
                tax_partrimoine: response[i].montant.tax_partrimoine,
                ttc: response[i].montant.arriere_ttc,//response[i].montant.ttc,
                tva: response[i].montant.tva,
                tva19: response[i].montant.tva19,
                ttcComul: this.comulTTC + parseFloat(response[i].montant.ttc),
                timber: 0,
              },
              patrimoine: {
                id: response[i].patrimoine.id
              },

              color: '',
            });
            this.listConstatation2.push({
              id: response[i].id,
              annee: response[i].annee,
              mois: response[i].mois,
              montant: {
                abbatement: response[i].montant.abbatement,
                arriere_ttc: response[i].montant.ttc,
                charge_arrieres: response[i].montant.charge_arrieres,
                charge_locative: response[i].montant.charge_locative,
                isov: response[i].montant.isov,
                layer_net: response[i].montant.layer_net,
                loyer_arrieres: response[i].montant.loyer_arrieres,
                loyer_principal: response[i].montant.layer_net,
                penalite_retard: response[i].montant.penalite_retard,
                tax_arrieres: response[i].montant.tax_arrieres,
                tax_partrimoine: response[i].montant.tax_partrimoine,
                ttc: response[i].montant.ttc,//response[i].montant.arriere_ttc,
                tva: response[i].montant.tva,
                tva19: response[i].montant.tva19,
                ttcComul: this.comulTTC + parseFloat(response[i].montant.ttc),
                timber: 0
              },
              patrimoine: {
                id: response[i].patrimoine.id
              },

              color: '',
            });
            if (parseFloat(response[i].montant.penalite_retard) != 0) {
              this.listConstatation[i].color = '0';
              this.listConstatation2[i].color = '0';
            } else {
              this.listConstatation[i].color = '1';
              this.listConstatation2[i].color = '1';
            }


            this.comulTTC = this.comulTTC + parseFloat(response[i].montant.ttc);

          }
        // } else {

        //   this.listConstatation.push({
        //     id: response.id,
        //     annee: response.annee,
        //     mois: response.mois,
        //     montant: {
        //       abbatement: response.montant.abbatement,
        //       arriere_ttc: response.montant.arriere_ttc,
        //       charge_arrieres: response.montant.charge_arrieres,
        //       charge_locative: response.montant.charge_locative,
        //       isov: response.montant.isov,
        //       layer_net: response.montant.layer_net,
        //       loyer_arrieres: response.montant.loyer_arrieres,
        //       loyer_principal: response.montant.layer_net,
        //       penalite_retard: response.montant.penalite_retard,
        //       tax_arrieres: response.montant.tax_arrieres,
        //       tax_partrimoine: response.montant.tax_partrimoine,
        //       ttc: response.montant.arriere_ttc,//response.montant.ttc,
        //       tva: response.montant.tva,
        //       tva19: response.montant.tva19,
        //       ttcComul: this.comulTTC + parseFloat(response.montant.ttc),
        //       timber: 0,
        //     },
        //     patrimoine: {
        //       id: response.patrimoine.id
        //     },

        //     color: '',
        //   });
        //   this.listConstatation2.push({
        //     id: response.id,
        //     annee: response.annee,
        //     mois: response.mois,
        //     montant: {
        //       abbatement: response.montant.abbatement,
        //       arriere_ttc: response.montant.ttc,
        //       charge_arrieres: response.montant.charge_arrieres,
        //       charge_locative: response.montant.charge_locative,
        //       isov: response.montant.isov,
        //       layer_net: response.montant.layer_net,
        //       loyer_arrieres: response.montant.loyer_arrieres,
        //       loyer_principal: response.montant.layer_net,
        //       penalite_retard: response.montant.penalite_retard,
        //       tax_arrieres: response.montant.tax_arrieres,
        //       tax_partrimoine: response.montant.tax_partrimoine,
        //       ttc: response.montant.ttc,//response.montant.arriere_ttc,
        //       tva: response.montant.tva,
        //       tva19: response.montant.tva19,
        //       ttcComul: this.comulTTC + parseFloat(response.montant.ttc),
        //       timber: 0,
        //     },
        //     patrimoine: {
        //       id: response.patrimoine.id
        //     },

        //     color: '',
        //   });
        //   if (parseFloat(response.montant.penalite_retard) != 0) {
        //     this.listConstatation[0].color = '0';
        //     this.listConstatation2[0].color = '0';
        //   } else {
        //     this.listConstatation[0].color = '1';
        //     this.listConstatation2[0].color = '1';
        //   }
        //   this.comulTTC = this.comulTTC + parseFloat(response.montant.ttc);

        // }
        this.gestionAfichageDeListe(this.listConstatation2);
      } else {

        this.getConstatationInPaimentLoyer();
      }
      console.log('Constatation 1:', this.listConstatation);
      console.log('Constatation 2:', this.listConstatation2);
    }, err => {
      this.loader = 'none';
      Swal.fire({
        title: 'Constatation ' + err.response.statusText,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      })
    })
  }

  getConstatationInPaimentLoyer() {
    this.constatationInPaimentLoyer = 1;
    this._commenService.getConstatationInPaimentLoyer(this.Obj.id, this.Obj.patrimoine.id).subscribe((response: any) => {
      console.log('Data get Constatation In PaimentLoyer :', response.length);
      if (response.length >= 1) {
        for (var i = 0; i < response.length; i++) {
          console.log('§§§§§§§§§ ', response[i].montant.ttc);
          this.listConstatation.push({
            id: response[i].id,
            annee: response[i].annee,
            mois: response[i].mois,
            montant: {
              abbatement: response[i].montant.abbatement,
              arriere_ttc: response[i].montant.arriere_ttc,
              charge_arrieres: response[i].montant.charge_arrieres,
              charge_locative: response[i].montant.charge_locative,
              isov: response[i].montant.isov,
              layer_net: response[i].montant.layer_net,
              loyer_arrieres: response[i].montant.loyer_arrieres,
              loyer_principal: response[i].montant.layer_net,
              penalite_retard: response[i].montant.penalite_retard,
              tax_arrieres: response[i].montant.tax_arrieres,
              tax_partrimoine: response[i].montant.tax_partrimoine,
              ttc: response[i].montant.ttc,
              tva: response[i].montant.tva,
              tva19: response[i].montant.tva19,
              ttcComul: this.comulTTC + parseFloat(response[i].montant.ttc),
              timber: response[i].montant.timber,
            },
            patrimoine: {
              id: response[i].patrimoine.id
            },

            color: '4',
          });
          this.listConstatation2.push({
            id: response[i].id,
            annee: response[i].annee,
            mois: response[i].mois,
            montant: {
              abbatement: response[i].montant.abbatement,
              arriere_ttc: response[i].montant.ttc,
              charge_arrieres: response[i].montant.charge_arrieres,
              charge_locative: response[i].montant.charge_locative,
              isov: response[i].montant.isov,
              layer_net: response[i].montant.layer_net,
              loyer_arrieres: response[i].montant.loyer_arrieres,
              loyer_principal: response[i].montant.layer_net,
              penalite_retard: response[i].montant.penalite_retard,
              tax_arrieres: response[i].montant.tax_arrieres,
              tax_partrimoine: response[i].montant.tax_partrimoine,
              ttc: response[i].montant.arriere_ttc,
              tva: response[i].montant.tva,
              tva19: response[i].montant.tva19,
              ttcComul: this.comulTTC + parseFloat(response[i].montant.ttc),
              timber: response[i].montant.timber,
            },
            patrimoine: {
              id: response[i].patrimoine.id
            },

            color: '4',
          });
          this.comulTTC = this.comulTTC + parseFloat(response[i].montant.ttc);

        }
      } else {

        this.listConstatation.push({
          id: response.id,
          annee: response.annee,
          mois: response.mois,
          montant: {
            abbatement: response.montant.abbatement,
            arriere_ttc: response.montant.arriere_ttc,
            charge_arrieres: response.montant.charge_arrieres,
            charge_locative: response.montant.charge_locative,
            isov: response.montant.isov,
            layer_net: response.montant.layer_net,
            loyer_arrieres: response.montant.loyer_arrieres,
            loyer_principal: response.montant.layer_net,
            penalite_retard: response.montant.penalite_retard,
            tax_arrieres: response.montant.tax_arrieres,
            tax_partrimoine: response.montant.tax_partrimoine,
            ttc: response.montant.ttc,
            tva: response.montant.tva,
            tva19: response.montant.tva19,
            ttcComul: this.comulTTC + parseFloat(response.montant.ttc),
            timber: response.montant.timber,
          },
          patrimoine: {
            id: response.patrimoine.id
          },
          /* paimentLoyer: {
               id: response.constatationPaimentLoyer[i].paimentLoyer.id
           },*/
          color: '4',
        });
        console.log('§§§AAAAAAAAA§§§ ', response.montant.ttc);
        this.listConstatation2.push({
          id: response.id,
          annee: response.annee,
          mois: response.mois,
          montant: {
            abbatement: response.montant.abbatement,
            arriere_ttc: response.montant.ttc,
            charge_arrieres: response.montant.charge_arrieres,
            charge_locative: response.montant.charge_locative,
            isov: response.montant.isov,
            layer_net: response.montant.layer_net,
            loyer_arrieres: response.montant.loyer_arrieres,
            loyer_principal: response.montant.loyer_principal,
            penalite_retard: response.montant.penalite_retard,
            tax_arrieres: response.montant.tax_arrieres,
            tax_partrimoine: response.montant.tax_partrimoine,
            ttc: response.montant.arriere_ttc,
            tva: response.montant.tva,
            tva19: response.montant.tva19,
            ttcComul: this.comulTTC + parseFloat(response.montant.ttc),
            timber: response.montant.timber,
          },
          patrimoine: {
            id: response.patrimoine.id
          },

          color: '4',
        });

        this.comulTTC = this.comulTTC + parseFloat(response.montant.ttc);

      }
      this.gestionAfichageDeListe(this.listConstatation2);
    }, error => {
      this.loader = 'none';
      Swal.fire({
        title: 'Constatation paiment loyer ' + error.response.statusText,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      })
      console.log('error :', error.response);
    })
  }

  gestionAfichageDeListe(listConstatation2) {
    this.listConstatation3 = [];

    this.de = 0;
    this.a = 0;
    this.annee = listConstatation2[0].annee;

    var i = 0;
    var j = 1;
    while (i < listConstatation2.length && j == 1) {
      if (listConstatation2[i].annee == this.annee) {
        this.a = this.a + 1;

      } else {
        j = 0;
      }
      i++;
    }
    this.listConstatation3 = listConstatation2.slice(this.de, this.a);
    this.de1 = this.de;
    this.a1 = this.a;
    if (this.a != listConstatation2.length) {
      this.bGris = 1;
    }
    console.log('listConstatation3 ', this.listConstatation3);
  }

  ajouterConstatationApayer() {
    this._commenService.ajouterConstatationApayer(this.Obj.patrimoine.id).subscribe((response: any) => {
      console.log('Data get Constatation AJOUTER: ', response);

      this.constatationAjouterApayer.push({
        montant: {
          abbatement: response.montant.abbatement,
          arriere_ttc: response.montant.arriere_ttc,
          charge_arrieres: response.montant.charge_arrieres,
          charge_locative: response.montant.charge_locative,
          layer_net: response.montant.layer_net,
          loyer_arrieres: response.montant.loyer_arrieres,
          loyer_principal: response.montant.loyer_principal,
          penalite_retard: 0,
          tax_arrieres: response.montant.tax_arrieres,
          tax_partrimoine: response.montant.tax_partrimoine,
          ttc: response.montant.ttc,
          tva: response.montant.tva,
          tva19: response.montant.tva19,
          ttcComul: this.comulTTC + parseFloat(response.montant.ttc),
          timber: response.montant.timber,
        },
      });
      console.log('ConsTATATION A AJOUTER  :', this.constatationAjouterApayer);
      // this.comulTTC = this.comulTTC + parseFloat(response.montant.ttc);
    }, error => {
      this.loader = 'none';
      Swal.fire({
        title: 'Constatation à payer ' + error.response.statusText,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      })
      console.log('error :', error.response);
    })
  }

  calculeFrais() {
    this._commenService.calculeFraisService(this.Obj.id).subscribe((response: any) => {
      console.log('response moad ', response);
      this.farisMiseDemeure = response;
      this.loader = 'none'
    }, error => {
      this.loader = 'none';
      Swal.fire({
        title: 'Calcule frais ' + error.response.statusText,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      })
      console.log('error :', error.response);
    })
  }

  paginationLeft() {
    if (this.de != 0) {
      this.listConstatation3 = [];
      this.a = this.de1;
      this.annee = this.annee - 1;

      var i = 0;

      var j = 1;
      var z = 1;
      while (i < this.listConstatation2.length && j == 1) {
        if (this.listConstatation2[i].annee == this.annee) {
          /* if(z==1){
               this.de=i;
               console.log('iiiiiiiiiiii ',i);
               z=0;
           }
           this.a=this.a+1;*/
          this.de = i;
          j = 0;

        }
        /*else{
                            j=0;
                        }*/
        i++;
      }


      if (this.a == this.de) {
        this.paginationLeft();
      } else {
        this.listConstatation3 = this.listConstatation2.slice(this.de, this.a);
      }
      this.de1 = this.de;
      this.a1 = this.a;

    }
  }

  paginationRight() {

    if (this.a <= this.listConstatation2.length - 1) {
      this.listConstatation3 = [];
      this.de = this.a;
      this.annee = parseFloat(this.annee.toString()) + 1;
      var i = this.a;

      var j = 1;
      while (i < this.listConstatation2.length && j == 1) {
        if (this.listConstatation2[i].annee == this.annee) {
          this.a = this.a + 1;

        } else {
          j = 0;
        }
        i++;
      }
      this.de1 = this.de;
      this.a1 = this.a;

      if (this.a == this.de) {
        this.paginationRight();
      } else {
        this.listConstatation3 = this.listConstatation2.slice(this.de, this.a);
      }

    }
  }

  moisRegler() {
    console.log('Engagement objet : ', this.engagement_entete);
    if (this.engagement_entete.id != 0 && this.nbrMoisRegle < this.engagement_entete.nbrs_mois_initiale) {
      Swal.fire({
        title: 'Nombre de mois à réglé inferieure à nombre de mois initiale ' + this.engagement_entete.nbrs_mois_initiale,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      });
      // return;
    }
    if (this.change == 1) {

      for (var j = 0; j < this.listConstatation2.length; j++) {
        if (this.listConstatation2[j].id != 0) {
          this.listConstatation2[j].color = this.listConstatation[j].color;
        } else {
          this.listConstatation2.splice(j);
        }
      }
      this.n = 0;
      this.m = 0;
      //this.moisplus = 0;
      if (this.listConstatation2.length == 0) {
        this.comulTTC = 0;
      } else {
        this.comulTTC = this.listConstatation2[this.listConstatation2.length - 1].montant.ttcComul;
      }

    }

    if (this.sauvgardeInterdit == 0) {
      //this.listConstatation2=[];
      if (this.Obj.patrimoine.id != 0) {
        this.change = 1;
        if (this.constatationInPaimentLoyer != 1) {

          if (parseInt(this.nbrMoisRegle.toString()) != 0) {
            if (parseInt(this.nbrMoisRegle.toString()) <= this.listConstatation2.length) {
              if (this.n <= parseInt(this.nbrMoisRegle.toString())) {
                for (var i = 0; i < parseInt(this.nbrMoisRegle.toString()); i++) {
                  // this.listConstatation2.push(this.listConstatation[i]);
                  this.listConstatation2[i].color = '3';
                  this.montantRegle = this.number(this.listConstatation2[i].montant.ttc);
                }
              } else {
                for (var j = parseFloat(this.nbrMoisRegle.toString()); j <= this.n; j++) {
                  // this.listConstatation2.push(this.listConstatation[i]);
                  this.listConstatation2[j].color = this.listConstatation[j].color;
                  this.montantRegle = this.number(this.listConstatation2[parseFloat(this.nbrMoisRegle.toString()) - 1].montant.ttc);
                }
              }
              this.n = parseFloat(this.nbrMoisRegle.toString());
            } else {
              //this.moisplus = 1;
              for (var s = 0; s < this.listConstatation2.length; s++) {
                this.listConstatation2[s].color = '3';
                //this.montantRegle=this.number(0);
              }
              // var linAjouter=this.listConstatation2.length-this.nbrMoisRegle;
              var tab_mois = new Array("janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre");

              var mois = tab_mois.indexOf(this.listConstatation2[this.listConstatation2.length - 1].mois);

              var annee = this.listConstatation2[this.listConstatation2.length - 1].annee;

              for (var k = this.listConstatation2.length; k < parseInt(this.nbrMoisRegle.toString()); k++) {
                if (mois == 11) {
                  mois = 0;
                  annee = parseFloat(annee) + 1;
                } else {
                  mois = mois + 1;
                }
                var comulttc = parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc) - parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.timber);
                var ttccomul2 = comulttc + parseFloat(this.constatationAjouterApayer[0].montant.ttc);
                var timber = this.timbre(ttccomul2);
                ttccomul2 = ttccomul2 + timber;
                console.log('TTC ANTICIPER 1 ', ttccomul2);
                // this.ajouterConstatationApayer(annee,tab_mois[mois],this.listConstatation2[this.listConstatation2.length - 1].patrimoine.id);
                this.listConstatation2.push({
                  id: 0,
                  annee: annee,
                  mois: tab_mois[mois],
                  montant: {
                    abbatement: this.constatationAjouterApayer[0].montant.abbatement,
                    arriere_ttc: this.constatationAjouterApayer[0].montant.arriere_ttc,
                    charge_arrieres: this.constatationAjouterApayer[0].montant.charge_arrieres,
                    charge_locative: this.constatationAjouterApayer[0].montant.charge_locative,
                    layer_net: this.constatationAjouterApayer[0].montant.layer_net,
                    loyer_arrieres: this.constatationAjouterApayer[0].montant.loyer_arrieres,
                    loyer_principal: this.constatationAjouterApayer[0].montant.loyer_principal,
                    penalite_retard: 0,
                    tax_arrieres: this.constatationAjouterApayer[0].montant.tax_arrieres,
                    tax_partrimoine: this.constatationAjouterApayer[0].montant.tax_partrimoine,
                    ttc: ttccomul2,
                    tva: this.constatationAjouterApayer[0].montant.tva,
                    tva19: this.constatationAjouterApayer[0].montant.tva19,
                    ttcComul: this.comulTTC + ttccomul2,
                    timber: timber,
                  },
                  patrimoine: {
                    id: this.listConstatation2[this.listConstatation2.length - 1].patrimoine.id
                  },

                  color: '3',
                });
                this.comulTTC = this.comulTTC + ttccomul2;
              }

              this.montantRegle = this.number(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc);
            }
          } else {
            for (var j = parseFloat(this.nbrMoisRegle.toString()); j <= this.n; j++) {
              this.listConstatation2[j].color = this.listConstatation[j].color;
              this.montantRegle = this.number(0);
            }
          }
        } else {

          //this.moisplus = 1;
          // var linAjouter=this.listConstatation2.length-this.nbrMoisRegle;
          var tab_mois = new Array("janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre");

          var mois = tab_mois.indexOf(this.listConstatation2[this.listConstatation2.length - 1].mois);

          var annee = this.listConstatation2[this.listConstatation2.length - 1].annee;
          var n1 = 1;
          for (var k = 0; k < parseInt(this.nbrMoisRegle.toString()); k++) {
            if (mois == 11) {
              mois = 0;
              annee = parseFloat(annee) + 1;
            } else {
              mois = mois + 1;
            }
            var comulttc = parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc) - parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.timber);
            var ttccomul2: number;
            if (n1 == 1) {
              console.log('/////////// 26  ', this.constatationAjouterApayer[0].montant.ttc);
              ttccomul2 = this.constatationAjouterApayer[0].montant.ttc;
              n1 = 2;
            } else {
              console.log('/////////// 27  ', this.constatationAjouterApayer[0].montant.ttc);
              ttccomul2 = comulttc + parseFloat(this.constatationAjouterApayer[0].montant.ttc);

            }
            var timber = this.timbre(ttccomul2);
            console.log('/////////// 30  ', ttccomul2);
            console.log('******* 2  ', timber);
            ttccomul2 = parseFloat(ttccomul2.toString()) + parseFloat(timber.toString());
            console.log('/////////// 22  ', ttccomul2);
            // ttccomul2=comulttc+parseFloat(this.constatationAjouterApayer[0].montant.ttc)+timber;

            //  this.ajouterConstatationApayer(annee,tab_mois[mois],this.listConstatation2[this.listConstatation2.length - 1].patrimoine.id);
            this.listConstatation2.push({
              id: 0,
              annee: annee,
              mois: tab_mois[mois],
              montant: {
                abbatement: this.constatationAjouterApayer[0].montant.abbatement,
                arriere_ttc: this.constatationAjouterApayer[0].montant.arriere_ttc,
                charge_arrieres: this.constatationAjouterApayer[0].montant.charge_arrieres,
                charge_locative: this.constatationAjouterApayer[0].montant.charge_locative,
                layer_net: this.constatationAjouterApayer[0].montant.layer_net,
                loyer_arrieres: this.constatationAjouterApayer[0].montant.loyer_arrieres,
                loyer_principal: this.constatationAjouterApayer[0].montant.loyer_principal,
                penalite_retard: 0,
                tax_arrieres: this.constatationAjouterApayer[0].montant.tax_arrieres,
                tax_partrimoine: this.constatationAjouterApayer[0].montant.tax_partrimoine,
                ttc: ttccomul2,
                tva: this.constatationAjouterApayer[0].montant.tva,
                tva19: this.constatationAjouterApayer[0].montant.tva19,
                ttcComul: this.comulTTC + ttccomul2,
                timber: timber,
              },
              patrimoine: {
                id: this.listConstatation2[this.listConstatation2.length - 1].patrimoine.id
              },

              color: '3',
            });
            this.comulTTC = this.comulTTC + ttccomul2;
          }

          this.montantRegle = this.number(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc);

        }

      } else {
        Swal.fire({
          title: 'vous devez choisir un Bénificiaire...!',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',

        })
      }
    } else {
      Swal.fire({
        title: 'vous devez Annuler la quitance puis modifier...!',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      })
    }
    this.montantRegle = this.number(parseFloat(this.calcule(this.montantRegle)) + this.farisMiseDemeure);
    this.calculerCommission();
    this.gestionAfichageDeListe(this.listConstatation2);

  }

  montantRegler() {
    this.nbrMoisRegle = 0;
    console.log('CHANGE  ', this.change);
    if (this.change == 1) {
      for (var j = 0; j < this.listConstatation2.length; j++) {
        console.log('id   ', this.listConstatation2[j].id);
        if (this.listConstatation2[j].id != 0) {
          this.listConstatation2[j].color = this.listConstatation[j].color;
        } else {
          this.listConstatation2.splice(j);
        }
      }
      if (this.listConstatation2.length == 0) {
        this.comulTTC = 0;
      } else {
        this.comulTTC = this.listConstatation2[this.listConstatation2.length - 1].montant.ttcComul;
      }

    }

    var copieMontantRegler = parseFloat(this.calcule(this.montantRegle));
    var montantMaxInList = parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc);
    var montantMinInList = parseFloat(this.listConstatation2[0].montant.ttc);
    console.log('copie Montant Regler ', copieMontantRegler);
    console.log('sauvgarde interdit  ', this.sauvgardeInterdit);
    if (this.sauvgardeInterdit == 0) {
      if (this.Obj.patrimoine.id != 0) {
        this.change = 1;
        if (this.constatationInPaimentLoyer != 1) {

          console.log('montant Max In List ', montantMaxInList);
          if (copieMontantRegler <= montantMaxInList) {
            console.log('montant Min In List ', montantMinInList);
            if (copieMontantRegler >= montantMinInList) {
              for (var i = 0; i < this.listConstatation2.length; i++) {
                if (parseFloat(this.listConstatation2[i].montant.ttc) <= copieMontantRegler) {
                  this.listConstatation2[i].color = '3';
                  console.log('Nombre mois regler ', this.nbrMoisRegle);
                  this.nbrMoisRegle = parseInt(this.nbrMoisRegle.toString()) + 1;
                  console.log('Nombre mois regler ', this.nbrMoisRegle);
                } else {
                  this.montantRegle = this.listConstatation2[i - 1].montant.ttc;
                  i = this.listConstatation2.length;
                }
              }
            } else {
              Swal.fire({
                title: 'Ce montant est insuffisant...!',
                icon: 'info',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
              })
            }
          } else {
            for (var s = 0; s < this.listConstatation2.length; s++) {
              this.listConstatation2[s].color = '3';
            }
            var tab_mois = new Array("janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre");
            var mois = tab_mois.indexOf(this.listConstatation2[this.listConstatation2.length - 1].mois);
            var annee = this.listConstatation2[this.listConstatation2.length - 1].annee;
            var k = 1;
            while (k == 1) {

              var comulttc = parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc) - parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.timber);
              var ttccomul2 = comulttc + parseFloat(this.constatationAjouterApayer[0].montant.ttc);
              var timber = this.timbre(ttccomul2);
              ttccomul2 = ttccomul2 + timber;
              //ttccomul2=comulttc+parseFloat(this.constatationAjouterApayer[0].montant.ttc)+timber;
              console.log('TTC ANTICIPER 2 ', ttccomul2);
              if (ttccomul2 > copieMontantRegler) {
                k = 0;
              } else {
                if (mois == 11) {
                  mois = 0;
                  annee = parseFloat(annee) + 1;
                } else {
                  mois = mois + 1;
                }

                this.listConstatation2.push({
                  id: 0,
                  annee: annee,
                  mois: tab_mois[mois],
                  montant: {
                    abbatement: this.constatationAjouterApayer[0].montant.abbatement,
                    arriere_ttc: this.constatationAjouterApayer[0].montant.arriere_ttc,
                    charge_arrieres: this.constatationAjouterApayer[0].montant.charge_arrieres,
                    charge_locative: this.constatationAjouterApayer[0].montant.charge_locative,
                    layer_net: this.constatationAjouterApayer[0].montant.layer_net,
                    loyer_arrieres: this.constatationAjouterApayer[0].montant.loyer_arrieres,
                    loyer_principal: this.constatationAjouterApayer[0].montant.loyer_principal,
                    penalite_retard: 0,
                    tax_arrieres: this.constatationAjouterApayer[0].montant.tax_arrieres,
                    tax_partrimoine: this.constatationAjouterApayer[0].montant.tax_partrimoine,
                    ttc: ttccomul2,
                    tva: this.constatationAjouterApayer[0].montant.tva,
                    tva19: this.constatationAjouterApayer[0].montant.tva19,
                    ttcComul: this.comulTTC + ttccomul2,
                    timber: timber,
                  },
                  patrimoine: {
                    id: this.listConstatation2[this.listConstatation2.length - 1].patrimoine.id
                  },

                  color: '3',
                });
                this.comulTTC = this.comulTTC + ttccomul2;
              }

            }
            console.log('MONTANT REGLER ', this.listConstatation2[this.listConstatation2.length - 1].montant.ttc);
            this.montantRegle = this.listConstatation2[this.listConstatation2.length - 1].montant.ttc;
            this.nbrMoisRegle = this.listConstatation2.length;
          }

        } else {

          var tab_mois = new Array("janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre");
          var mois = tab_mois.indexOf(this.listConstatation2[this.listConstatation2.length - 1].mois);
          var annee = this.listConstatation2[this.listConstatation2.length - 1].annee;
          var k = 1;
          var n1 = 1;
          while (k == 1) {
            var comulttc = parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.ttc) - parseFloat(this.listConstatation2[this.listConstatation2.length - 1].montant.timber);
            var ttccomul2: number;
            if (n1 == 1) {
              console.log('/////////// 26  ', this.constatationAjouterApayer[0].montant.ttc);
              ttccomul2 = this.constatationAjouterApayer[0].montant.ttc;
              n1 = 2;
            } else {
              console.log('/////////// 27  ', this.constatationAjouterApayer[0].montant.ttc);
              ttccomul2 = comulttc + parseFloat(this.constatationAjouterApayer[0].montant.ttc);

            }
            var timber = this.timbre(ttccomul2);
            console.log('/////////// 30  ', ttccomul2);
            console.log('******* 2  ', timber);
            ttccomul2 = parseFloat(ttccomul2.toString()) + parseFloat(timber.toString());
            console.log('/////////// 22  ', ttccomul2);
            // ttccomul2=comulttc+parseFloat(this.constatationAjouterApayer[0].montant.ttc)+timber;
            console.log('TTC ANTICIPER 2 ', ttccomul2);
            if (ttccomul2 > copieMontantRegler) {
              k = 0;
            } else {
              if (mois == 11) {
                mois = 0;
                annee = parseFloat(annee) + 1;
              } else {
                mois = mois + 1;
              }

              this.listConstatation2.push({
                id: 0,
                annee: annee,
                mois: tab_mois[mois],
                montant: {
                  abbatement: this.constatationAjouterApayer[0].montant.abbatement,
                  arriere_ttc: this.constatationAjouterApayer[0].montant.arriere_ttc,
                  charge_arrieres: this.constatationAjouterApayer[0].montant.charge_arrieres,
                  charge_locative: this.constatationAjouterApayer[0].montant.charge_locative,
                  layer_net: this.constatationAjouterApayer[0].montant.layer_net,
                  loyer_arrieres: this.constatationAjouterApayer[0].montant.loyer_arrieres,
                  loyer_principal: this.constatationAjouterApayer[0].montant.loyer_principal,
                  penalite_retard: 0,
                  tax_arrieres: this.constatationAjouterApayer[0].montant.tax_arrieres,
                  tax_partrimoine: this.constatationAjouterApayer[0].montant.tax_partrimoine,
                  ttc: ttccomul2,
                  tva: this.constatationAjouterApayer[0].montant.tva,
                  tva19: this.constatationAjouterApayer[0].montant.tva19,
                  ttcComul: this.comulTTC + ttccomul2,
                  timber: timber,
                },
                patrimoine: {
                  id: this.listConstatation2[this.listConstatation2.length - 1].patrimoine.id
                },

                color: '3',
              });
              this.comulTTC = this.comulTTC + ttccomul2;
            }

          }
          console.log('MONTANT REGLER ', this.listConstatation2[this.listConstatation2.length - 1].montant.ttc);
          this.montantRegle = this.listConstatation2[this.listConstatation2.length - 1].montant.ttc;
          this.nbrMoisRegle = this.listConstatation2.length - this.listConstatation.length;
        }
      } else {
        Swal.fire({
          title: 'vous devez choisir un Bénificiaire...!',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        })
      }
    } else {
      Swal.fire({
        title: 'vous devez Annuler la quitance puis modifier...!',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      })
    }
    this.montantRegle = this.number(this.montantRegle);
    this.calculerCommission();
    this.gestionAfichageDeListe(this.listConstatation2);
  }

  sauvegarder() {
    console.log('LIST CONS 2 §§§§ ', this.listConstatation2);
    console.log('§§§§§§§!!!!!222!!!!!!! ', this.listConstatation);
    
    if (this.engagement_entete.id != 0 && this.nbrMoisRegle < this.engagement_entete.nbrs_mois_initiale) {
      Swal.fire({
        title: 'Nombre de mois à réglé inferieure à nombre de mois initiale ' + this.engagement_entete.nbrs_mois_initiale,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      });
      return;
    }
    if (!this.isConditionCheked) {
      Swal.fire({
        title: 'Vous devez accepter le CGU',
        icon: 'error',
        width: '60rem',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      })
    } else {
      if (this.sauvgardeInterdit != 1) {
        if (this.Obj.patrimoine.id != 0) {
          var listDesConstatations: Constatation[] = [];
          var i = 0;
          var j = 1;
          if (this.constatationInPaimentLoyer != 1) {
            var t = 0;
            var z = 0;
            while (i < this.listConstatation2.length && j == 1) {
              console.log('TTC ! ', this.listConstatation2[i].montant.arriere_ttc);
              if (this.listConstatation2[i].color == '3') {

                listDesConstatations.push({
                  id: this.listConstatation2[i].id,
                  annee: this.listConstatation2[i].annee,
                  mois: this.listConstatation2[i].mois,
                  montant: {
                    abbatement: this.listConstatation2[i].montant.abbatement,
                    arriere_ttc: this.listConstatation2[i].montant.arriere_ttc,
                    charge_arrieres: this.listConstatation2[i].montant.charge_arrieres,
                    charge_locative: this.listConstatation2[i].montant.charge_locative,
                    layer_net: this.listConstatation2[i].montant.layer_net,
                    loyer_arrieres: this.listConstatation2[i].montant.loyer_arrieres,
                    loyer_principal: this.listConstatation2[i].montant.loyer_principal,
                    penalite_retard: this.listConstatation2[i].montant.penalite_retard,
                    tax_arrieres: this.listConstatation2[i].montant.tax_arrieres,
                    tax_partrimoine: this.listConstatation2[i].montant.tax_partrimoine,
                    ttc: this.listConstatation2[i].montant.ttc,
                    tva: this.listConstatation2[i].montant.tva,
                    tva19: this.listConstatation2[i].montant.tva19,
                    timber: this.listConstatation2[i].montant.timber,
                    // tax_arrieres: 0,
                    // tax_partrimoine: 0,
                    ov: {
                      id: 0
                    }
                  },
                  patrimoine: {
                    id: this.listConstatation2[i].patrimoine.id
                  },
                  affectation: {
                    id: this.Obj.id
                  },
                  // machine: {
                  //   utilisateur: 'louBNA'
                  // },
                  farisMiseDemeure: this.farisMiseDemeure,
                });



                if (this.listConstatation2[i].id == 0) {
                  console.log('JJJJJJJJJJJJJJJ 22 1');
                  console.log('JJJJJJJJJJJJJJJ 22 ', this.listConstatation[this.listConstatation.length - 1].montant.ttc);
                  listDesConstatations[t].montant.ttc = this.listConstatation[this.listConstatation.length - 1].montant.ttc;
                  listDesConstatations[t].montant.arriere_ttc = this.listConstatation[this.listConstatation.length - 1].montant.arriere_ttc;
                  listDesConstatations[t].montant.timber = this.listConstatation[this.listConstatation.length - 1].montant.timber;
                } else {
                  z = i;
                  console.log('JJJJJJJJJJJJJJJ 11 33 ',);
                  console.log('T  2 ', t);
                  listDesConstatations[t].montant.ttc = this.listConstatation[i].montant.ttc;
                  listDesConstatations[t].montant.arriere_ttc = this.listConstatation[i].montant.arriere_ttc;
                }
                console.log('JllllllllllJJJJJ 1 ');
                t = t + 1;
                console.log('JllllllllllJJJJJ 2 ', t);
              } else {
                j = 0;
              }
              i++;
            }
          } else {
            console.log('%%%%%%%%%%% ');
            while (i < this.listConstatation2.length) {

              if (this.listConstatation2[i].id != 0) {

                this.idConstatation = this.listConstatation2[i].id;

              }
              if (this.listConstatation2[i].color == '3') {

                listDesConstatations.push({
                  id: this.listConstatation2[i].id,
                  annee: this.listConstatation2[i].annee,
                  mois: this.listConstatation2[i].mois,
                  montant: {
                    abbatement: this.listConstatation2[i].montant.abbatement,
                    arriere_ttc: this.listConstatation[this.listConstatation.length - 1].montant.arriere_ttc,
                    charge_arrieres: this.listConstatation2[i].montant.charge_arrieres,
                    charge_locative: this.listConstatation2[i].montant.charge_locative,
                    layer_net: this.listConstatation2[i].montant.layer_net,
                    loyer_arrieres: this.listConstatation2[i].montant.loyer_arrieres,
                    loyer_principal: this.listConstatation2[i].montant.loyer_principal,
                    penalite_retard: this.listConstatation2[i].montant.penalite_retard,
                    tax_arrieres: this.listConstatation2[i].montant.tax_arrieres,
                    tax_partrimoine: this.listConstatation2[i].montant.tax_partrimoine,
                    ttc: this.listConstatation[this.listConstatation.length - 1].montant.ttc,
                    tva: this.listConstatation2[i].montant.tva,
                    tva19: this.listConstatation2[i].montant.tva19,
                    timber: this.listConstatation[this.listConstatation.length - 1].montant.timber,
                    // tax_arrieres: 0,
                    // tax_partrimoine: 0,
                    ov: {
                      id: 0
                    }
                  },
                  patrimoine: {
                    id: this.listConstatation2[i].patrimoine.id
                  },
                  affectation: {
                    id: this.Obj.id
                  },
                  // machine: {
                  //   utilisateur: 'louBNA'
                  // },
                  farisMiseDemeure: this.farisMiseDemeure,
                });

              }
              i++;
            }
          }
          //console.log('%%%%%%%%%%% ',listDesConstatations);
          if (listDesConstatations.length != 0) {
            const objPaiement = new ObjPaiement(this.idCaisse, (this.isDahabiyaCheked)? "CCP":"CIB", "at", this.numCheque, this.idConstatation, listDesConstatations.length, listDesConstatations[listDesConstatations.length - 1].montant.timber, 0, listDesConstatations[0].patrimoine.id, this.farisMiseDemeure, this.isDahabiyaCheked);
            console.log('is dahabeya', objPaiement.modeDahabeya);
            localStorage.setItem('objPaiement', this._cryptoService.encrypt(objPaiement));
            debugger;
            Swal.fire({
              title: 'Vous ne pourrez pas revenir en arrière!',
              inputAttributes: {
                autocapitalize: 'off'
              },
              showCancelButton: true,
              confirmButtonText: 'Oui, Sauvgarder',
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
                this.listepaimentLoyerInserer = [];
                this.getUrlObj.constatation = listDesConstatations;
                this.getUrlObj.objPaiement = objPaiement;//this._cryptoService.decrypt(localStorage.getItem('objPaiement'));
                this._commenService.payerParDahabia(this.getUrlObj).subscribe((response: any) => {
                  console.log(response);
                  window.location.href = response.formUrl;
                }, error => {
                  Swal.fire({
                    title: 'Erreur dans la Sauvgarde',
                    icon: 'error',
                    width: '60rem',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                  })
                })
              },
              allowOutsideClick: () => !Swal.isLoading()
            })

          } else {
            Swal.fire({
              title: 'Choisir les mois à payer d abord ...!',
              icon: 'info',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',

            })
          }
        } else {
          Swal.fire({
            title: 'Choisir un bénificiaire...!',
            icon: 'info',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',

          })
        }
      } else {
        Swal.fire({
          title: 'Vous avez deja sauvgarder...!',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',

        })
      }
    }
  }

  number(number) {
    console.log("in : ", number);
    var numbe = this._decimalPipe.transform(this.calcule(number), "1.2-2")
    console.log("out : ", number);
    return numbe;
  }

  calcule(number) {
    if (typeof number !== 'undefined') {
      // console.log("calcule in : ", number);
      var number1 = number.toString();
      var str = number1.replace(/\s/g, '');
      str = str.replace(',', '');
      var str1 = str;
      // console.log("calcule out : ", str1);
      return str1;
    }
  };

  timbre(ttc: number): number {
    var timbre = 0;
    // var modulo;
    // var timberCalculer = 0;
    // if (this.espece == 'EAT') {
    //   timbre = parseFloat(ttc.toString()) / 100;
    //   if (timbre > 2500) {
    //     timberCalculer = 2500;
    //   } else {
    //     var f = ttc % 100;
    //     modulo = parseInt(f.toString());
    //     timberCalculer = parseInt(timbre.toString()) + 5;

    //   }

    // } else {

    //   timberCalculer = 0;
    // }
    // cette methode été commenter pour  rendre le timbre a 0 ds epayment
    return timbre;
  };

  getColor(colorNumber: string) {
    switch (colorNumber) {
      case '0':
        return 'bg-danger'
        break;
      case '1':
        return 'bg-warning'
        break;
      case '4':
        return 'bg-primary'
        break;
      default:
        return 'bg-success'
    }
  }

  focusMontantRegle() {
    if (this.montantRegle == '0.00') {
      this.montantRegle = '';
    } else {
      this.montantRegle = this.calcule(this.montantRegle);
    }
  }

  blurMontantRegle() {
    if (this.montantRegle == '') {
      this.montantRegle = '0.00';
    } else {
      this.montantRegle = this.number(this.montantRegle);
    }
  }

  onLogout() {
    this._commenService.logout();
  }

  checkBoxvalue() {
    this.isConditionCheked = !this.isConditionCheked;
  }

  onChangeCard() {
    this.isDahabiyaCheked = !this.isDahabiyaCheked;
    this.nbrMoisRegle = 0;
    this.moisRegler();
  }

  calculerCommission() {
    var montantRegleNumber = parseFloat(this.calcule(this.montantRegle));
    var montantDiver = 0;
    if (this.montantRegle != '0.00') {
      if (this.isDahabiyaCheked) {
        if (montantRegleNumber * 0.008 > 500) {
          montantDiver = 500;
        } else {
          montantDiver = montantRegleNumber * 0.008;
        }
      } else {
        if (montantRegleNumber < 5000) {
          montantDiver = 11.9;
        } else {
          console.log("montantRegleNumber*0.00833: ", montantRegleNumber * 0.00833);
          if (montantRegleNumber * 0.00833 > 300) {
            montantDiver = 300;
          } else {
            montantDiver = montantRegleNumber * 0.00833;
          }
        }
      }
      console.log("montantDiver: ", montantDiver);
      this.montantRegle = this.number(parseFloat(this.calcule(this.montantRegle)) + montantDiver);
      console.log("montantRegle: ", this.montantRegle);
    }
  }

  showConditions() {
    window.open('../../assets/Conditions_Generales_Epaiement.pdf', '_blank');
  }

  getEngagementEntete() {
    if (this.Obj.patrimoine.numero != '') {
      this._commenService.GetEngagementFromPaiment(this.Obj.patrimoine.numero).subscribe((response: any) => {
        console.log('Enagement : ' , response);
        if (response != null) {
          this.engagement_entete = response;
          // this.engagement_entete.date_engagement = format(new Date(response.data.date_engagement.substring(10, 0)), 'yyyy-MM-dd')
        } else {
          console.log('is here ELSE');
        }
      }, error => {
        console.log('error : ' + error.response);
      })
    }
  }

  moisReglerEntred() {
    console.log('moisReglerEntred ');
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.moisRegler();
    }, 800);
  }

  ngOnInit(): void {
    this.getBenif();
  }

}
