<div id=login>
  <div class="limiter">
    <div class="container-login100" style="display: flex;
    flex-direction: column;
    align-items: center;background-image: url('../assets/images/cne.jpg');">
      <div class="row pb-5 pt-5 mx-auto flex-container" style="display: flex;
      flex-direction: column;
      align-items: center;">
        <h4>وزارة السكن و العمران و المدينة</h4>
        <h4>Ministère de l'Habitat, l'Urbanisme et la Ville</h4>
        <h4>ديوان الترقية و التسيير العقاري قسنطينة</h4>
        <h4>Office de Promotion et de la Gestion Immobilière Constantine</h4>
        <h4>مرحبا بكم في بوابة الدفع الإلكتروني المؤمنة</h4>
        <h4>Bienvenue au portail de paiement électronique sécurisé</h4>
      </div>
      <div class="wrap-login100">
        <div class="login100-form validate-form">
          <!-- <span class="login100-form-logo">
                          <i class="zmdi zmdi-landscape"></i>
                      </span> -->
          <img src="../assets/images/logo.jpg" class="rounded-circle mx-auto d-block" width="130" height="130">
          <span class="login100-form-title p-b-34 p-t-27">

          </span>
          <form [formGroup]="ngForm" (ngSubmit)="onSubmit()">
            <div class="wrap-input100 validate-input" data-validate="Entrer code locataire">
              <input class="form-control" type="text" 
                     formControlName="idpatrimoine" placeholder="Code Locataire"  
                      id="idpatrimoine">
              <span class="focus-input100" data-placeholder="&#xf207;"></span>
            </div>

            <input class="form-control" type="password"
                     formControlName="password" placeholder="Mot de Passe"   id="password" >
              <span class="focus-input100" data-placeholder="&#xf191;"></span>
        

            <ngx-recaptcha2 id="recaptcha_challenge_image" [size]="size" 
            #captchaElem [siteKey]="siteKey" (success)="handleSuccess($event)">
            </ngx-recaptcha2>

            <div class="container-login100-form-btn mt-3">
              <button class="login100-form-btn" name="captcha" id="captcha"  type="submit">
                Connecter</button>
            </div>
          </form>
          <!-- <div class="text-center p-t-90">
                <a class="txt1" href="#">
                  Mot de passe oublié?
                </a>
              </div> -->

        </div>
      </div>
      <div class="mt-3">
        <h5>Copyright &copy; {{year}} OPGI. Powered by <a href="https://www.idweb-dz.com/" target="_blank" style="
        font-size: 20px !important;">IDWEB</a>
        </h5>
      </div>
    </div>
  </div>
</div>