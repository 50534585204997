import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { DecimalPipe } from '@angular/common';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PaiementComponent } from './paiement/paiement.component';
import { LoginInterceptor } from './login/login-interceptor';
import { AuthGuard } from './login/auth.guard';
import { PayementEffectueComponent } from './payement-effectue/payement-effectue.component';
import { NgxCaptchaModule } from 'ngx-captcha';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { 
    path: 'PaiementConstatation', 
    component: PaiementComponent,
    canActivate:[AuthGuard] 
  },
  { 
    path: 'paiementeffectue', 
    component: PayementEffectueComponent,
    canActivate:[AuthGuard] 
  },
  { path: '**', component: LoginComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PaiementComponent,
    PayementEffectueComponent,
  ],
  imports: [
    BrowserModule,    
    FormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [DecimalPipe, { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
