import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { CommenService } from '../commen.service';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
 // @ViewChild('f') signupForm: NgForm;
  public captchaSuccess = false;
  siteKey = environment.siteKey;
  year = new Date().getFullYear();
  size = 'compact';
  constructor(private _commenService: CommenService, private _route: Router) {

  }

  ngForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
    idpatrimoine: new FormControl('', [Validators.required]),
  
  });
 
  onSubmit() {
    if (this.ngForm.value.password != '' && this.ngForm.value.idpatrimoine != '' 
    // && this.captchaSuccess != false
    ) {
        console.log("=============<",this.ngForm.value.password);
      this._commenService.login(this.ngForm.value.idpatrimoine, this.ngForm.value.password)
        .subscribe((responseDate) => {
          this._route.navigate(['PaiementConstatation']);
        }, err => {
          Swal.fire({
            title: `Code Locataire n'existe pas veuillez contacter OPGI ..!`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          })
        });
    }
  }

  handleSuccess(data) {
    if (data != null || data != '')
      this.captchaSuccess = true;
  }

}
