export class Login {
   constructor(
      public identifiant: string,
      private idToken: string,
      private expirationDate: Date
   ) { }

   get token(){
      console.log('in token')
      if(!this.expirationDate || new Date() > this.expirationDate){
         console.log('in token return ull');
         return null;
      }
      console.log('return token ');
      return this.idToken;
   }
}