// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Base_URL: "http://127.0.0.1:8088",
  Base_URL:"https://api.opgiconstantine.dz",
  crypto_js_key : "idweb@opgi.20/21",
  // siteKey : "6LcaqC4bAAAAAJhgLGKqgZ2gLGx6f0SGVhPAZsvA"
  siteKey : "6LekwjEbAAAAABqFcGRaIi9uOGJV-PYt6qLUEGeI"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
