import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { tap, take, exhaustMap } from "rxjs/operators";
import { AuthResponseData } from "./models/authResponseData.model";
import { Constatation } from "./models/constatation.model";
import { Login } from "./models/login.model";
import { ObjPaiement } from "./models/objPaiement.model";
import { OrderStatusDahabya } from "./models/orderStatusDahabya.model";
import { environment } from "../environments/environment";
import { GetUrlEpaymentDTO } from "./models/getUrlDTO.model";

@Injectable({ providedIn: 'root' })
export class CommenService {
    loginSubject = new BehaviorSubject<Login>(null);
    private tokenExpirationTimer: any;
    private apiUrl = environment.Base_URL + '/OPGI/Login/getPatrimoineAuth';
    constructor(private _http: HttpClient, private _router: Router) { }

    login(user: String, pass: String): Observable<AuthResponseData> {
        const body = { numPat: user, passWord: pass };
        return this._http.post<AuthResponseData>(this.apiUrl, body).pipe(
            tap(resData => {
              const log = new Login(resData.identifiant, resData.idToken, new Date(+resData.expirationDate));
              this.loginSubject.next(log);
              localStorage.setItem('userData', JSON.stringify(log));
              const duration = new Date(+resData.expirationDate).getTime() - new Date().getTime();
              this.autoLogOut(duration);
            })
          );
        }

    autoLogin() {
        console.log('autologin');
        const userData: {
            identifiant: string;
            idToken: string;
            expirationDate: string
        } = JSON.parse(localStorage.getItem('userData'));

        if (!userData) {
            return;
        }
        const localLogin = new Login(userData.identifiant, userData.idToken, new Date(userData.expirationDate));

        if (localLogin.token) {
            console.log('in auto login from ap comp');
            this.loginSubject.next(localLogin);
            var exp = new Date(userData.expirationDate).getTime();
            var now = new Date().getTime();
            const duration = new Date(userData.expirationDate).getTime() - new Date().getTime();
            localStorage.setItem('exp', exp.toString());
            localStorage.setItem('now', now.toString());
            localStorage.setItem('duration', duration.toString());
            this.autoLogOut(duration);
        }
    }

    logout() {
        console.log('logout');
        this.loginSubject.next(null);
        localStorage.setItem('data cleard', new Date().getTime().toString());
        localStorage.removeItem('userData');
        localStorage.removeItem('objPaiement');
        if (!this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = null;
        this._router.navigate(['']);
    }

    autoLogOut(expitationDuration: number) {
        console.log('auto logout');
        console.log('in log out', expitationDuration);
        this.tokenExpirationTimer = setTimeout(() => {
            this.logout();
        }, expitationDuration);
    }

    getBenificiaire() {
        return this.loginSubject.pipe(
            take(1),
            exhaustMap(user => {
                return this._http.get(environment.Base_URL + '/OPGI/Benificiare/' + user.identifiant);
            }));

    }

    getConstatation(idAffectation: number, idBenificiare: number) {
        return this._http.get(environment.Base_URL + '/OPGI/Constatation/GetConstatation/' + idAffectation + '/' + idBenificiare);
    }

    getConstatationInPaimentLoyer(idAffectation: number, idBenificiare: number) {
        return this._http.get(environment.Base_URL + '/OPGI/Constatation/Anticiper/' + idAffectation + '/' + idBenificiare);
    }

    ajouterConstatationApayer(idBenificiare: number) {
        return this._http.get(environment.Base_URL + '/OPGI/Constatation/GenererConstatation/' + idBenificiare);
    }

    calculeFraisService(idAffectation: number) {
        return this._http.get(environment.Base_URL + '/OPGI/MiseDemeure/calculeFrais?idAffectation=' + idAffectation);
    }

    payerParDahabia(listDesConstatations: GetUrlEpaymentDTO) {

        return this.loginSubject.pipe(
            take(1),
            exhaustMap(user => {
                return this._http.post(environment.Base_URL + '/OPGI/EPayement/getUrl/' + user.identifiant, listDesConstatations,
                    {
                        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
                        // params : new HttpParams().set('numPatrimoine',user.identifiant)
                    });
            }));

    }

    checkOperationDahabeya(orderId,modeDahabeya) {
        return this._http.get(environment.Base_URL + '/OPGI/EPayement/checkOperationDahabeya/' + orderId + '/' + modeDahabeya,
        {
            headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
        });
    }

    reverseOperationDahabeya(orderId, amount, modeDahabeya) {
        return this._http.get<OrderStatusDahabya>(environment.Base_URL + '/OPGI/EPayement/reverseOperationDahabeya/' + orderId + '/' + amount + '/' + modeDahabeya);
    }

    sauvegarderPaiement(objPaiement: ObjPaiement) {
        return this._http
            .post(
                environment.Base_URL + '/OPGI/EPayement', objPaiement,
                {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' }),
                    responseType: 'blob'
                });
    }

    sendQuittanceMail(email, data: ObjPaiement) {
        
        var mail = JSON.stringify(data);
        var test = {data};
        return this._http.post(environment.Base_URL + '/OPGI/EPayement/sendEmail/' + email, test,
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
            });
    }

    GetEngagementFromPaiment(codeLocataire){
        return this._http.get(environment.Base_URL + '/OPGI/Engagement/getEngagement/' + codeLocataire);
    }
}